/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, onClick, style, ...props }) => (
  <i
    className={`fas fa-${name}`}
    {...props}
    onClick={onClick}
    style={onClick ? { cursor: 'pointer', padding: 8, ...style } : style}
  />
);

Icon.defaultProps = {
  onClick: undefined,
  style: undefined,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Icon;
