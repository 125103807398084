/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../assets/flags/freakflags.css';

type UserFlagProps = {
  field?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: Record<string, any>,
  size?: 'sm' | 'md' | 'lg' | 'xl',
  format?: 'square' | 'round',
};

export const UserFlag = (props: UserFlagProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { user, field = 'country', size = 'md', format = 'round' } = props;

  const value = user?.[field];
  if (!value) return null;
  const code = value.length > 2 ? t(`countries.${value}`) : value;
  return <span className={`flag fflag fflag-${code.toUpperCase()} ff-${format} ff-${size}`} />;
};

UserFlag.defaultProps = {
  field: 'country',
};
