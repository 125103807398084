import { translateObject } from '@appcraft/common-utils';
import { useEffect, useMemo, useRef } from 'react';
import i18n, { updateCustomTranslations } from './i18n';

const languages = {
  fr: 'fr-FR',
  en: 'en-US',
};

export function useLanguage(lang = 'fr') {
  const curLanguage = useRef(i18n.language);
  const newLanguage = languages[lang] || lang;
  if (newLanguage !== curLanguage.current) {
    curLanguage.current = newLanguage;
    i18n.changeLanguage(newLanguage);
  }
}

export function useCustomStrings(lang = 'fr', customStrings) {
  useLanguage(lang);
  useEffect(() => {
    if (customStrings) {
      updateCustomTranslations(lang, customStrings || {});
    }
  }, [lang, customStrings]);
}

export function useTranslatedObject(object, lang) {
  return useMemo(() => translateObject(object, lang), [object, lang]);
}
