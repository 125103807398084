/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './TabFilter.scss';

const TabFilter = ({ activeTab, labelKey, onChange, t, tabs }) => {
  return (
    <div className="tab-filter pt-2">
      {labelKey && <span className=" mr-2">{t(labelKey)}</span>}
      {tabs.map((tab) => (
        <span
          key={tab}
          className={cx('label label-rounded mr-2', activeTab === tab && 'label-primary')}
          onClick={() => onChange(tab)}
        >
          {t(tab)}
        </span>
      ))}
    </div>
  );
};

TabFilter.defaultProps = {
  labelKey: undefined,
};

TabFilter.propTypes = {
  activeTab: PropTypes.string.isRequired,
  labelKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TabFilter;
