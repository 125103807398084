import PropTypes from 'prop-types';
import Button from './Button';

export const ActionButton = (props) => {
  const { action, enable, t, onClick, icons, labels } = props;

  return (
    <Button
      className={enable ? 'btn-primary' : undefined}
      icon={!enable ? icons[action] : icons[`no-${action}`]}
      label={t(!enable ? labels[action] : labels[`no-${action}`])}
      onClick={onClick}
    />
  );
};

ActionButton.defaultProps = {
  icons: {},
  labels: {},
};

ActionButton.propTypes = {
  action: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  icons: PropTypes.object,
  labels: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
