/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Label.scss';

// eslint-disable-next-line react/prop-types
const Label = ({
  as: Component,
  // eslint-disable-next-line react/prop-types
  children,
  color,
  rounded,
  className,
  onClick,
  style,
  ...props
}) => (
  <Component
    className={cx('label', color && `label-${color}`, rounded && 'label-rounded', className)}
    {...props}
    onClick={onClick}
    style={{ cursor: onClick ? 'pointer' : undefined, ...style }}
  >
    {children}
  </Component>
);

Label.defaultProps = {
  as: 'span',
  className: undefined,
  color: undefined,
  onClick: undefined,
  rounded: undefined,
  style: undefined,
};

Label.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'warning', 'error', 'bordered']),
  onClick: PropTypes.func,
  rounded: PropTypes.bool,
  style: PropTypes.object,
};

export default Label;
