/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import cx from 'classnames';

export const ToggleButton = (props) => {
  const { label, onClick, checked, icon } = props;
  return (
    <div className="form-group" onClick={onClick}>
      <label className="form-switch">
        <input type="checkbox" checked={checked} />
        <i className="form-icon" />
        {icon && <i className={cx(`fas fa-${icon}`, { active: checked })} />}
        {label}
      </label>
    </div>
  );
};
