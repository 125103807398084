import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = (props) => {
  const { onClick, label, icon, className } = props;
  return (
    <button className={cx('btn', className)} onClick={onClick} type="button">
      {icon && <i className={`fas fa-${icon}`} />} {label}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  icon: undefined,
  label: '',
};

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default Button;
