import React from 'react';
import PropTypes from 'prop-types';
import countBy from 'lodash/countBy';
import cx from 'classnames';

const tabs = [
  {
    key: 'current',
    label: 'lastQuestions',
    count: (q) => !q.state || q.state === 'idle' || q.state === 'visible',
  },
  { key: 'replied', label: 'already-answered', count: (q) => !!q.response },
  { key: 'archived', label: 'archived', state: 'archived' },
  { key: 'blacklisted', label: 'blacklisted', state: 'blacklisted' },
  {
    key: 'popular',
    label: 'popular',
    count: (q) => q.voteCount,
  },
];

const Tabs = (props) => {
  const { onSelect, questions, t, tab, votes } = props;
  const questionsCount = countBy(questions, 'state');

  const handleSelect = (e, newTab) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(newTab);
  };

  return (
    <ul className="tab">
      {tabs.map(({ key, label, state, count }) => {
        if (key === 'popular' && !votes) return null;
        return (
          <li key={key} className={cx('tab-item', { active: tab === key })}>
            <a
              href="#tabs"
              className="badge"
              data-badge={(count ? questions.filter(count).length : questionsCount[state]) || 0}
              onClick={(e) => handleSelect(e, key)}
            >
              {t(label)}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
Tabs.defaultProps = {
  votes: false,
};
Tabs.propTypes = {
  onSelect: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  votes: PropTypes.bool,
};

export default Tabs;
