import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../utils';

export default class Pin extends Component {
  state = {
    pin: '',
  };

  handleChange = (e) => this.setState({ pin: e.target.value });

  render() {
    const { onChange, session } = this.props;
    const { pin } = this.state;
    return (
      <div
        style={{
          margin: '0 auto',
          marginTop: 16,
          maxWidth: 300,
        }}
      >
        {session && (
          <div style={{ textAlign: 'center' }}>
            <h2>{session.title}</h2>
          </div>
        )}
        <p>Veuillez entrez le code PIN pour poser des questions</p>
        <form className="form-group" onSubmit={noop}>
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            className="form-input"
            placeholder="PIN"
            value={pin}
            onChange={this.handleChange}
          />
        </form>
        <button
          type="submit"
          style={{ width: '100%' }}
          disabled={pin.length < 4}
          className="btn btn-primary"
          onClick={() => onChange(pin)}
        >
          Valider
        </button>
      </div>
    );
  }
}

Pin.propTypes = {
  session: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
