import firebase from 'firebase/app';
import 'firebase/firestore';
// import "firebase/auth";

const firebaseConfigEU = {
  apiKey: 'AIzaSyDsYkawsmn5oIpMVbzFYx278mXVG9QPyBE',
  authDomain: 'appcraft-events-eu.firebaseapp.com',
  databaseURL: 'https://appcraft-events-eu.firebaseio.com',
  projectId: 'appcraft-events-eu',
  storageBucket: 'appcraft-events-eu.appspot.com',
  messagingSenderId: '781101762227',
  appId: '1:781101762227:web:adac4cc703fb6cca',
};
const fb = firebase.initializeApp(firebaseConfigEU);
export const firestore = fb.firestore();
export default firestore.collection('live-questions');
