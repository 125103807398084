import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Label from '../components/Label';
import Styles from '../components/Styles';
import Template from '../components/Template';
import database from '../core/database';
import withFirebase from '../hoc/withFirebase';
import { useLanguage } from '../translations/translations.hooks';
import { defaultUserLabel, formatTime } from '../utils';
import { questionsArrayPropTypes, sessionPropTypes } from '../utils/propTypes';
import { computeQuestions } from '../utils/formatQuestions';
import Button from '../components/Button';
import { ActionButton } from '../components/ActionButton';

const QuestionCard = ({
  displayUserInfo,
  id,
  onStateChange,
  question,
  showTime,
  theme,
  shareUserInfo,
  t,
  template,
  pushToSlide,
  user,
}) => (
  <div className="card" style={{ marginBottom: 8 }}>
    <div className="card-header">
      <div className="card-subtitle text-gray">
        {formatTime(showTime)}
        {theme && (
          <>
            &nbsp;&nbsp;
            <Label color="primary" rounded>
              {theme}
            </Label>
          </>
        )}
        {displayUserInfo && shareUserInfo && (
          <Template
            className="card-body--user-info"
            template={template?.userLabel || defaultUserLabel}
            data={user}
          />
        )}
      </div>
    </div>
    <div className="card-body">
      <span className="card-body--question">{question}</span>
    </div>
    <div className="card-footer">
      <Button
        icon="archive"
        className="btn"
        label={t('btn.archive')}
        onClick={() =>
          onStateChange(id, { state: 'archived', pushToSpeaker: false, pushToSlide: false })
        }
        type="button"
      />
      <ActionButton
        action="pushToSlide"
        enable={pushToSlide}
        labels={{ pushToSlide: 'btn.show-on-slides', 'no-pushToSlide': 'btn.hide-from-slides' }}
        icons={{ pushToSlide: 'users', 'no-pushToSlide': 'users-slash' }}
        id={id}
        onClick={() => onStateChange(id, { pushToSlide: !pushToSlide })}
        t={t}
      />
    </div>
  </div>
);

QuestionCard.defaultProps = {
  template: undefined,
  user: undefined,
  shareUserInfo: true,
};
QuestionCard.propTypes = {
  id: PropTypes.string.isRequired,
  onStateChange: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  shareUserInfo: PropTypes.string,
  showTime: PropTypes.object.isRequired,
  displayUserInfo: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  template: PropTypes.string,
  user: PropTypes.object,
};

const SpeakerSession = ({ match, session, questionsForSpeaker, questionsForSlide }) => {
  const { lang = 'fr', showUserInfoForSpeaker = false, template } = session;
  const questions = computeQuestions(questionsForSlide, questionsForSpeaker);
  useLanguage(lang);
  const { t } = useTranslation();
  const setQuestionState = (id, state) => {
    database
      .doc(match.params.appId)
      .collection('sessions')
      .doc(match.params.sessionId)
      .collection('questions')
      .doc(id)
      .update({ state });
  };
  const setQuestionStatus = (id, value) => {
    database
      .doc(match.params.appId)
      .collection('sessions')
      .doc(match.params.sessionId)
      .collection('questions')
      .doc(id)
      .update(value);
  };

  return (
    <div className="container container--speaker" style={{ maxWidth: 800, margin: '0 auto' }}>
      <Styles
        primaryColor={session.primaryColor}
        customCSS={session.customCSS}
        {...session.design}
      />
      <div className="hero" style={{ paddingTop: 16, paddingBottom: 16 }}>
        <div className="hero-body" style={{ textAlign: 'center' }}>
          <h1>{session.title}</h1>

          <p>{t('questionCount', { count: questions.length })}</p>
        </div>
      </div>
      <div className="content">
        {questions.length === 0 && (
          <div className="empty">
            <div className="empty-icon">
              <i className="icon icon-3x icon-time" />
            </div>
            <p className="empty-title h5">{t('speakerView')}</p>
            <p className="empty-subtitle">{t('noQuestionYet')}</p>
          </div>
        )}
        {orderBy(questions, 'showTime', 'asc').map((q) => (
          <QuestionCard
            key={q.id}
            {...q}
            displayUserInfo={showUserInfoForSpeaker}
            onStateChange={setQuestionStatus}
            t={t}
            template={template}
          />
        ))}
      </div>
    </div>
  );
};

SpeakerSession.defaultProps = {
  questionsForSpeaker: [],
  questionsForAll: [],
};
SpeakerSession.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string,
      sessionId: PropTypes.string,
    }),
  }).isRequired,
  questionsForSpeaker: questionsArrayPropTypes,
  questionsForAll: questionsArrayPropTypes,
  session: sessionPropTypes.isRequired,
};

export default withFirebase(SpeakerSession, ({ match }) => ({
  session: database.doc(match.params.appId).collection('sessions').doc(match.params.sessionId),
  //No Or op in firestore :'(
  questionsForSpeaker: database
    .doc(match.params.appId)
    .collection('sessions')
    .doc(match.params.sessionId)
    .collection('questions')
    .where('pushToSpeaker', '==', true),
  questionsForSlide: database
    .doc(match.params.appId)
    .collection('sessions')
    .doc(match.params.sessionId)
    .collection('questions')
    .where('pushToSlide', '==', true),
}));
