/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VoteCounter from '../screens/ClientSession/components/VoteCounter';
import { formatDateAndTimeToday } from '../utils';
import { questionsArrayPropTypes, votesArrayPropTypes } from '../utils/propTypes';
import Avatar from './Avatar';
import Icon from './Icon';
import './QuestionList.scss';
import Label from './Label';

const Question = ({
  createdAt,
  enableVote,
  icon,
  id,
  onVote,
  response,
  question,
  showDate,
  showUserName,
  t,
  voteCount,
  voted,
  user,
  theme,
  ...rest
}) => {
  const { firstName, lastName } = user;
  const { strings } = rest;
  const handleVote = (e, questionId) => {
    e.preventDefault();
    e.stopPropagation();
    onVote(questionId);
  };

  const hasHeader = showUserName || showDate || enableVote;
  return (
    <div className="card card--question mb-2 mt-2">
      {hasHeader && (
        <div className="card-header p-2">
          {showUserName && <Avatar size="md" user={user} strings={strings} className="mr-2" />}
          <div className="user-and-date" style={{ flex: 1 }}>
            {showUserName && (firstName || lastName) && (
              <div className="card-title">
                {firstName} {lastName}
              </div>
            )}
            {showDate && (
              <div className="card-subtitle">
                <Icon name="clock" /> {formatDateAndTimeToday(createdAt)}{' '}
              </div>
            )}
          </div>
          {enableVote && (
            <VoteCounter
              icon={icon}
              hasVoted={voted}
              count={voteCount}
              onClick={(e) => handleVote(e, id)}
            />
          )}
        </div>
      )}
      <div className="card-body">
        {!!theme && (
          <div>
            <Label color="primary" className="px-2 py-1" style={{ borderRadius: 4 }}>
              {theme}
            </Label>
          </div>
        )}
        {question}
      </div>

      {response && (
        <div className="card-footer p-2">
          <div className="accordion question-response">
            <input type="checkbox" id={id} name="accordion-checkbox" hidden />
            <label className="accordion-header p-0" htmlFor={id}>
              <i className="icon icon-arrow-right mr-1" />
              {t('answer')}
            </label>
            <div className="accordion-body mb-0">{response}</div>
          </div>
        </div>
      )}
    </div>
  );
};

Question.defaultProps = {
  icon: undefined,
  response: undefined,
  theme: undefined,
  user: {},
  voteCount: 0,
  voted: false,
};
Question.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  enableVote: PropTypes.bool.isRequired,
  onVote: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  response: PropTypes.string,
  showUserName: PropTypes.bool.isRequired,
  showDate: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string,
  user: PropTypes.shape({
    userId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    thumbnail: PropTypes.object,
  }),
  voteCount: PropTypes.number,
  voted: PropTypes.bool,
};

const QuestionList = ({
  icon,
  className,
  enableVote,
  questions,
  onVote,
  showUserName,
  showDate,
  votes,
  strings,
}) => {
  const { t } = useTranslation();
  const votesById = groupBy(votes, 'id');

  return (
    <div className={cx('questions-list', className)}>
      <div className="questions-count">{t('questionCount', { count: questions.length })}</div>
      {questions.map((question) => {
        const voted = question.id in votesById;
        return (
          <Question
            {...question}
            enableVote={enableVote}
            icon={icon}
            key={question.id}
            onVote={onVote}
            showDate={showDate}
            showUserName={showUserName}
            t={t}
            voted={voted}
            strings={strings}
          />
        );
      })}
    </div>
  );
};

QuestionList.defaultProps = {
  icon: undefined,
  className: undefined,
  enableVote: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onVote: () => {},
  questions: [],
  showUserName: false,
  showDate: false,
  strings: {},
  votes: [],
};

QuestionList.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  enableVote: PropTypes.bool,
  onVote: PropTypes.func,
  questions: questionsArrayPropTypes,
  showUserName: PropTypes.bool,
  showDate: PropTypes.bool,
  strings: PropTypes.object,
  votes: votesArrayPropTypes,
};

export default QuestionList;
