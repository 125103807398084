import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import { stringFormatter } from './formatUtils';
import fr from './fr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: en,
      },
      fr: {
        common: fr,
      },
    },
    defaultNS: 'custom',
    fallbackNS: 'common',
    ns: ['common', 'custom'],
    // debug: true,
    lng: window.__DATA__?.lang || 'fr-FR',

    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: '|',

      // eslint-disable-next-line no-unused-vars
      format: stringFormatter,
    },
  });

export function updateCustomTranslations(language, translations) {
  i18n.addResourceBundle(language, 'custom', translations, true, true);
  i18n.changeLanguage(language); // Forces page refresh
}

export default i18n;
