import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import Client from './screens/Client';
import ClientSession from './screens/ClientSession/index';
import ModeratedClientSession from './screens/ClientSession/ModeratedClientSession';
import ModeratorSession from './screens/ModeratorSession';
import SlideSession from './screens/SlideSession';
import SpeakerSession from './screens/SpeakerSession';

function hasCustomDomain() {
  // TODO: handle sandbox ?
  return window.location.host !== 'live.appcraft.events';
}

function App() {
  return (
    <Router basename={hasCustomDomain() ? '/qa' : undefined}>
      <Switch>
        <Route
          exact
          path="/:appId/client/"
          component={({ match }) => <Client {...match.params} />}
        />
        <Route
          path="/:appId/client/:sessionId"
          component={({ match, location }) => {
            return <ClientSession {...match.params} location={location} />;
          }}
        />
        <Route
          path="/:appId/moderated/:sessionId"
          component={({ match, location }) => {
            return <ModeratedClientSession {...match.params} location={location} />;
          }}
        />
        <Route path="/:appId/moderator/:sessionId" component={ModeratorSession} />
        <Route path="/:appId/speaker/:sessionId" component={SpeakerSession} />
        <Route path="/:appId/slide/:sessionId" component={SlideSession} />
      </Switch>
    </Router>
  );
}

export default App;
