import memoizeOne from 'memoize-one';
import deburr from 'lodash/deburr';
import orderBy from 'lodash/orderBy';
import escapeRegExp from 'lodash/escapeRegExp';
import keyBy from 'lodash/keyBy';

const separationBlankCharacter = /[\n\r\t\f\v]/;

const formatBlacklistWord = (s) => escapeRegExp(s).trim();

const prepareBlacklist = (rawBlackList) => {
  return deburr(rawBlackList)
    .split(separationBlankCharacter)
    .map(formatBlacklistWord)
    .filter((s) => s)
    .map((s) => `\\b${s}\\b`)
    .join('|');
};

const compileBlackList = memoizeOne(
  (rawBlackList) => new RegExp(prepareBlacklist(rawBlackList), 'i'),
);

export const formatQuestions = (questions, rawBlackList) => {
  const blackList = rawBlackList ? compileBlackList(rawBlackList) : /^$/;

  return questions.map((q) => {
    const { question } = q;
    if (blackList.test(question)) {
      return { ...q, state: 'blacklisted' };
    }
    return q;
  });
};

export const sanitizeQuestions = (questions, blacklist, settings) => {
  const { enableOrderBy, orderType } = settings;
  const formatedQuestions = formatQuestions(questions, blacklist);
  const filteredQuestions = formatedQuestions.filter(
    (q) => q.state !== 'blacklisted' && q.state !== 'archived',
  );
  const finalQuestions = filteredQuestions.map((question) => ({
    ...question,
    createdAt: question.createdAt?.seconds
      ? new Date(question.createdAt.seconds * 1000).toISOString()
      : question.createdAt,
  }));
  const orderedQuestions = orderBy(
    finalQuestions,
    enableOrderBy && orderType === 'popularity' ? 'voteCount' : 'createdAt',
    'desc',
  );
  return orderedQuestions;
};

export const prepareTopicList = (topicList) => {
  if (!topicList) return [];
  return topicList
    .split(separationBlankCharacter)
    .map((str) => str.trim())
    .filter((s) => s);
};

export function computeQuestions(questionsForSlide, questionsForSpeaker) {
  const allQuestions = [...questionsForSlide, ...questionsForSpeaker];
  const allQuestionsById = keyBy(allQuestions, 'id');
  return Object.values(allQuestionsById);
}
