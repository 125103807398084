import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import database from '../core/database';
import { getTheme } from '../core/Store';
import withFirebase from '../hoc/withFirebase';
import { useLanguage } from '../translations/translations.hooks';
import ClientSession from './ClientSession/index';

require('iframe-resizer/js/iframeResizer.contentWindow.js');

const Card = ({ id, onClick, time, title }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div className="card" style={{ marginBottom: 8 }} onClick={() => onClick(id)}>
    <div className="card-header">
      <div className="card-title h5">{title}</div>
      {time && <div className="card-subtitle text-gray">{time}</div>}
    </div>
  </div>
);
Card.defaultProps = {
  time: undefined,
};
Card.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  time: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const Client = ({ sessions, appId, theme }) => {
  const [sessionId, setSessionId] = useState(null);
  const { t } = useTranslation();
  useLanguage(sessions[0]?.lang);

  if (sessionId) {
    return (
      <ClientSession
        appId={appId}
        theme={theme}
        sessionId={sessionId}
        onClose={() => setSessionId(null)}
      />
    );
  }
  return (
    <div className="container" style={{ maxWidth: 800, margin: '0 auto' }}>
      <p>{t('selectSession')}</p>
      {sessions.map((session) => (
        <Card key={session.id} {...session} onClick={setSessionId} />
      ))}
    </div>
  );
};

Client.defaultProps = { sessions: [], theme: {} };

Client.propTypes = {
  appId: PropTypes.string.isRequired,
  theme: PropTypes.object,
  sessions: PropTypes.array,
};

export default withFirebase(Client, ({ appId }) => ({
  theme: database.doc(appId).collection('themes').doc(getTheme()),
  sessions: database.doc(appId).collection('sessions'),
}));
