import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '../../../utils';
import { sessionPropTypes } from '../../../utils/propTypes';
import './QuestionBlock.scss';

const CommonBlock = ({
  header,
  hasTopic,
  question,
  loading,
  onQuestionChange,
  onThemeChange,
  onSend,
  onShareUserInfo,
  shareUserInfo,
  t,
  strings,
  theme,
  topicList,
  showUserNameChoice,
}) => {
  function getString(key) {
    return get(strings, key) || t(key);
  }
  const askQuestionButtonLabel = getString('askAQuestion');
  const description = getString('description');
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <h3>{header || getString('askYourQuestion')}</h3>
      </div>

      <form className="form-group" onSubmit={noop}>
        {description && <span className="form-description">{description}</span>}
        {hasTopic && (
          <select
            className="form-select"
            name="theme"
            value={theme}
            onChange={(e) => {
              onThemeChange(e.target.value);
            }}
          >
            <option value="">{getString('chooseATheme')}</option>
            {topicList.map((topic) => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
          </select>
        )}
        <textarea
          className="form-input"
          placeholder={getString('question-placeholder')}
          value={question}
          rows={3}
          onChange={(e) => {
            onQuestionChange(e.target.value);
          }}
        />
        <div>
          {showUserNameChoice && (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label>
              <input
                name="shareUserInfo"
                type="checkbox"
                checked={shareUserInfo}
                onChange={(e) => onShareUserInfo(e.target.checked)}
              />
              {t('share-user-info-label')}
            </label>
          )}
        </div>
      </form>
      <button
        className={`btn btn-primary ${loading ? 'loading' : ''}`}
        disabled={question.length < 4 || (hasTopic && theme.length === 0)}
        onClick={onSend}
        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        type="submit"
      >
        {askQuestionButtonLabel && (
          <span style={{ marginRight: 10 }}>{askQuestionButtonLabel}</span>
        )}
        <i className="fas fa-paper-plane" />
      </button>
    </>
  );
};

CommonBlock.defaultProps = {
  hasTopic: false,
  header: undefined,
  strings: {},
  theme: undefined,
  topicList: undefined,
  showUserNameChoice: false,
};
CommonBlock.propTypes = {
  hasTopic: PropTypes.bool,
  header: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  onThemeChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onShareUserInfo: PropTypes.func.isRequired,
  shareUserInfo: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  strings: PropTypes.object,
  theme: PropTypes.string,
  t: PropTypes.func.isRequired,
  topicList: PropTypes.array,
  showUserNameChoice: PropTypes.bool,
};

const QuestionBlock = (props) => {
  const { loading, onSubmit, session, topicList } = props;
  const { t } = useTranslation();

  const [mobileQuestionBlock, setMobileQuestionBlock] = useState(false);
  const [question, setQuestion] = useState('');
  const [theme, setTheme] = useState('');
  const {
    header,
    showQuestions = false,
    strings = {},
    showUserName,
    inputMode = 'button',
  } = session;
  const [shareUserInfo, setShareUserInfo] = useState(
    showUserName === true || showUserName === 'user-choice',
  );
  const hasTopic = topicList?.length > 0;
  const showUserNameChoice = showUserName === 'user-choice';

  function getString(key) {
    return get(strings, key) || t(key);
  }

  const handleSend = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit({ question, theme, shareUserInfo: showUserName && shareUserInfo });
    if (mobileQuestionBlock) setMobileQuestionBlock(false);
    setQuestion('');
    setTheme('');
  };
  return (
    <div className={cx({ 'mobile-footer-button': showQuestions })}>
      <div
        className={
          mobileQuestionBlock
            ? 'mobile-block'
            : cx('question-block', inputMode, { 'no-questions': !showQuestions })
        }
      >
        {mobileQuestionBlock && (
          <button
            className="btn btn-primary btn-action "
            onClick={() => {
              setMobileQuestionBlock(false);
            }}
            type="button"
          >
            <i className="icon icon-cross" />
          </button>
        )}
        <CommonBlock
          hasTopic={hasTopic}
          header={header}
          loading={loading}
          onQuestionChange={(value) => setQuestion(value)}
          onThemeChange={(value) => setTheme(value)}
          onSend={handleSend}
          onShareUserInfo={setShareUserInfo}
          shareUserInfo={shareUserInfo}
          question={question}
          theme={theme}
          t={t}
          strings={strings}
          topicList={topicList}
          showUserNameChoice={showUserNameChoice}
        />
      </div>
      {inputMode === 'button' && (
        <button
          className={cx('btn btn-primary mobile-button', {
            'no-questions': !showQuestions || mobileQuestionBlock,
          })}
          onClick={() => {
            setMobileQuestionBlock(true);
          }}
          type="button"
        >
          {getString('askAQuestion')}
        </button>
      )}
    </div>
  );
};

QuestionBlock.defaultProps = {
  topicList: undefined,
};
QuestionBlock.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  session: sessionPropTypes.isRequired,
  topicList: PropTypes.arrayOf(PropTypes.string),
};

export default QuestionBlock;
