import React from 'react';
import PropTypes from 'prop-types';
import { compile } from 'handlebars';

const Template = ({ as: Component, template, data, ...props }) => {
  if (!template) return null;

  const renderer = compile(template);
  const html = renderer(data).trim();
  if (!html) return null;
  return (
    <Component
      {...props}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

Template.defaultProps = {
  as: 'span',
  data: undefined,
  template: undefined,
};

Template.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  data: PropTypes.object,
  template: PropTypes.string,
};

export default Template;
