import React from 'react';
import Font from './Font';

export const CSS = ({ css }) => (
  <style
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: css,
    }}
  />
);

export default class Styles extends React.PureComponent {
  render() {
    const { primaryColor, customCSS = '', fonts } = this.props;
    return (
      <>
        {fonts && <Font type="body" font={fonts.body} />}
        <CSS
          css={`
            :root {
              --ac-color-primary: ${primaryColor};
            }

            /* Color styles */

            /* Custom CSS */
            ${customCSS}
          `}
        />
      </>
    );
  }
}
