import { useRequest } from 'ahooks';
import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import QuestionList from '../../components/QuestionList';
import Styles from '../../components/Styles';
import TabFilter from '../../components/TabFilter';
import database from '../../core/database';
import { getTheme, getUserInfo } from '../../core/Store';
import withFirebase from '../../hoc/withFirebase';
import qaService from '../../services/qa.service';
import { useCustomStrings, useTranslatedObject } from '../../translations/translations.hooks';
import { getParameterByName } from '../../utils';
import { prepareTopicList, sanitizeQuestions } from '../../utils/formatQuestions';
import {
  questionsArrayPropTypes,
  sessionPropTypes,
  votesArrayPropTypes,
} from '../../utils/propTypes';
import '../client.scss';
import './ClientSession.scss';
import CloseBar from './components/CloseBar';
import Pin from './components/Pin';
import QuestionBlock from './components/QuestionBlock';

const cnHosts = ['cn.hr.events.lvmh.com', 'cn.events.3ds.com'].map((host) =>
  host.replace('cn.cn', 'cn'),
);

function isCnHost() {
  const loc = window.location.host;
  return !!cnHosts.find((host) => {
    return loc.includes(host);
  });
}

class RawClientSession extends Component {
  state = {
    loading: false,
    message: null,
    pin: '',
    selectedTab: 'popularity',
  };

  // componentDidMount() {
  //   this.ping();
  //   this.trackInterval = setInterval(this.ping, 5 * 60 * 1000);
  // }

  // componentWillUnmount() {
  //   if (this.trackInterval) clearInterval(this.trackInterval);
  // }

  // ping = async () => {
  //   const { appId, location, sessionId } = this.props;
  //   const { userId } = getUserInfo(location);

  //   await fetch(
  //     // eslint-disable-next-line max-len
  //     `https://europe-west2-appcraft-events-eu.cloudfunctions.net/api/${appId}/live-questions/${sessionId}/ping?userId=${userId}`,
  //     {
  //       mode: 'no-cors', // 'cors' by default
  //     },
  //   );
  // };

  handleClearMessage = () => this.setState({ message: null });

  handleSend = async (values, t) => {
    this.setState({ loading: true });
    const { appId, location, sessionId, session, disableFirebase } = this.props;

    const { question, theme, shareUserInfo } = values;
    const {
      userId: id = null,
      firstName = null,
      lastName = null,
      email = null,
      thumbnail = null,
      ...extra
    } = getUserInfo(location);

    if (disableFirebase) {
      await qaService.postQuestion(appId, sessionId, {
        question,
        theme,
        userId: id,
        shareUserInfo,
      });
    } else {
      await database
        .doc(appId)
        .collection('sessions')
        .doc(sessionId)
        .collection('questions')
        .add({
          createdAt: new Date(),
          question,
          theme,
          shareUserInfo,
          voteCount: 0,
          user: shareUserInfo ? { id, firstName, lastName, email, thumbnail, extra } : { id },
        });
    }

    const timeout = setInterval(() => {
      this.setState({ message: '' });
      clearInterval(timeout);
    }, 3000);

    this.setState({
      loading: false,
      message: session.messageSent || t('messageSent'),
    });
  };

  handlePinChange = (pin) => this.setState({ pin: (pin || '').trim() });

  toggleVote = async (questionId) => {
    const { appId, location, sessionId, votes, updateVotes, disableFirebase } = this.props;
    const { userId, firstName = null, lastName = null, email = null } = getUserInfo(location);

    const votesById = groupBy(votes, 'id');
    const voted = !!votesById[questionId];

    // TODO: refacto into "service"
    if (disableFirebase) {
      if (!voted) {
        await qaService.addVote(appId, sessionId, questionId, {
          userId,
          firstName,
          lastName,
          email,
          questionId,
        });
      } else {
        await qaService.deleteVote(appId, sessionId, questionId, { userId });
      }
      // await qaService.postQuestion(appId, sessionId, { question, theme, userId: id });

      if (updateVotes) {
        // Force refresh in 3s
        setTimeout(updateVotes, 3000);
      }
      return;
    }

    const db = database.doc(appId).collection('sessions').doc(sessionId);
    if (!voted) {
      // add vote doc
      await db
        .collection('users')
        .doc(userId)
        .collection('votes')
        .doc(questionId)
        .set(
          omitBy(
            { userId, firstName, lastName, email, questionId, createdAt: new Date() },
            isUndefined,
          ),
        );
    } else {
      // delete vote doc
      await db.collection('users').doc(userId).collection('votes').doc(questionId).delete();
    }
  };

  toggleOrder = (value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { onClose, questions, session, votes, t, theme } = this.props;
    const { loading, message, pin, selectedTab } = this.state;
    const {
      blacklist,
      design = {},
      enableOrderBy = false,
      enableVote = false,
      icon,
      topicList,
      showDate = false,
      showQuestions = false,
      showUserName = false,
      strings = {},
    } = session;
    const { header, footer } = design;
    const topicArray = prepareTopicList(topicList);
    const hasTopic = topicArray.length > 0;

    const sanitizedQuestions = sanitizeQuestions(questions, blacklist, {
      enableOrderBy,
      orderType: selectedTab,
    });

    if (session.pin && session.pin !== pin) {
      return (
        <div className="container" style={{ maxWidth: 800, margin: '0 auto' }}>
          <CloseBar onClose={onClose} />
          <Pin session={session} onChange={this.handlePinChange} />
        </div>
      );
    }
    const extraDesign = theme?.client?.design || {};

    return (
      <div className="container container--client">
        <Styles
          fonts={theme?.fonts}
          {...session.design}
          primaryColor={session.primaryColor || theme?.primaryColor}
          customCSS={`${session.client?.design?.customCSS || ''}\n\n${
            session.design?.customCSS || ''
          }\n\n${extraDesign?.customCSS || ''}`}
        />
        <CloseBar onClose={onClose} />
        {header && header.uri && (
          <div className="image image--header" style={header.wrapperStyle}>
            <img alt="header" src={header.uri} style={header.imageStyle} />
          </div>
        )}

        {enableOrderBy && (
          <TabFilter
            activeTab={selectedTab}
            onChange={this.toggleOrder}
            t={t}
            labelKey="order-by"
            tabs={['popularity', 'date']}
          />
        )}
        <div className="tab-content">
          {showQuestions && (
            <QuestionList
              className={cx(
                { 'theme-active': hasTopic, 'order-by-active': enableOrderBy },
                'questions-container',
              )}
              icon={icon}
              enableVote={enableVote}
              showDate={showDate}
              showUserName={showUserName}
              questions={sanitizedQuestions}
              onVote={this.toggleVote}
              votes={votes}
              strings={strings}
            />
          )}
          <QuestionBlock
            loading={loading}
            onSubmit={(values) => this.handleSend(values, t)}
            session={session}
            topicList={topicArray}
          />
        </div>
        {!!message && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className="toast toast-success"
            onClick={this.handleClearMessage}
            style={{ marginTop: 8 }}
          >
            {message}
          </div>
        )}

        {footer && footer.uri && (
          <div className="image image--footer" style={footer.wrapperStyle}>
            <img alt="footer" src={footer.uri} style={footer.imageStyle} />
          </div>
        )}
      </div>
    );
  }
}

RawClientSession.defaultProps = {
  location: undefined,
  onClose: undefined,
  questions: [],
  votes: [],
};
RawClientSession.propTypes = {
  appId: PropTypes.string.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }),
  onClose: PropTypes.func,
  questions: questionsArrayPropTypes,
  session: sessionPropTypes.isRequired,
  sessionId: PropTypes.string.isRequired,
  votes: votesArrayPropTypes,
};
const ClientSession = withTranslation()(RawClientSession);

function filterQuestions(questionsRef, type, { userId }) {
  switch (type) {
    case 'onlyOwnQuestions':
      return questionsRef.where('user.id', '==', userId);
    case 'validated':
      return questionsRef.where('state', '==', 'visible');
    default:
      return questionsRef;
  }
}

const FBClientSessionWithQuestions = withFirebase(
  ClientSession,
  ({ appId, sessionId, location, session }) => {
    const { userId } = getUserInfo(location);
    const sessionRef = database.doc(appId).collection('sessions').doc(sessionId);
    const { showQuestions = false, enableVote = false } = session;
    const questionsRef = sessionRef.collection('questions').orderBy('createdAt', 'asc');
    const dataToFetch = { questions: filterQuestions(questionsRef, showQuestions, { userId }) };

    if (enableVote) {
      dataToFetch.votes = sessionRef.collection('users').doc(userId).collection('votes');
    }
    return dataToFetch;
  },
);

const PollingClientSessionWithQuestions = (props) => {
  const { appId, sessionId, location, session } = props;
  const { userId } = getUserInfo(location);
  const { enableVote = false } = session;
  const { data: questions = [] } = useRequest(
    async () => qaService.fetchQuestions(appId, sessionId, { userId }),
    {
      pollingInterval: 5000,
      pollingWhenHidden: false,
      refreshOnWindowFocus: true,
    },
  );
  const { data: votes = [], run: updateVotes } = useRequest(
    async () => qaService.fetchSelfVotes(appId, sessionId, { userId }),
    {
      ready: enableVote,
      pollingInterval: 30000,
      pollingWhenHidden: false,
      refreshOnWindowFocus: true,
    },
  );
  return <ClientSession {...props} questions={questions} votes={votes} updateVotes={updateVotes} />;
};

function waitForSession(Comp) {
  return (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!props.session) return null;
    return <Comp {...props} />;
  };
}

const ClientSessionReboot = waitForSession((props) => {
  const { session, polling } = props;

  const lang = getParameterByName('lang') || session.lang || 'fr';
  const translatedSession = useTranslatedObject(session, lang);
  useCustomStrings(lang, translatedSession.strings);
  if (!session) return null; // Not yet ready

  const { showQuestions, enableVote } = session;

  if (!showQuestions || showQuestions === 'none') {
    // No need to fetch questions/votes
    return <ClientSession {...props} session={translatedSession} />;
  }

  if (polling) {
    return (
      <PollingClientSessionWithQuestions
        key={`${showQuestions}-${enableVote}`}
        {...props}
        session={translatedSession}
      />
    );
  }

  // Reboot component on showQuestions/enableVote change
  return (
    <FBClientSessionWithQuestions
      key={`${showQuestions}-${enableVote}`}
      {...props}
      session={translatedSession}
    />
  );
});

const FirebaseSession = withFirebase(ClientSessionReboot, ({ appId, sessionId }) => ({
  theme: database.doc(appId).collection('themes').doc(getTheme()),
  session: database.doc(appId).collection('sessions').doc(sessionId),
}));

const PollingSession = (props) => {
  const { appId, sessionId } = props;
  const { data: session } = useRequest(async () => qaService.fetchSession(appId, sessionId), {
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
  });
  return <ClientSessionReboot {...props} polling session={session} theme={session?.theme} />;
};

const FBOrPollingClientSession = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { disableFirebase } = queryString?.parse?.(props.location?.search) ?? {};
  if (isCnHost() || (disableFirebase && disableFirebase !== 'false')) {
    return <PollingSession {...props} disableFirebase />;
  }
  return <FirebaseSession {...props} />;
};

export default FBOrPollingClientSession;
