import PropTypes from 'prop-types';

const { arrayOf, bool, number, object, shape, string } = PropTypes;

export const questionPropTypes = {
  id: string.isRequired,
  question: string.isRequired,
  voteCount: number,
  voted: bool,
  user: shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

export const sessionPropTypes = shape({
  blacklist: string,
  customCSS: string,
  design: object,
  enableOrderBy: bool,
  enableVote: bool,
  header: string,
  icon: string,
  messageSent: string,
  pin: string,
  primaryColor: string,
  showQuestions: PropTypes.oneOfType([bool, string]),
  title: string,
  topicList: string,
});

export const votePropTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  questionId: string.isRequired,
  userId: string.isRequired,
};

export const questionsArrayPropTypes = arrayOf(shape(questionPropTypes));
export const votesArrayPropTypes = arrayOf(shape(votePropTypes));
