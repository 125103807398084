import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class CloseBar extends PureComponent {
  render() {
    const { onClose } = this.props;
    if (!onClose) return null;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '.4rem',
        }}
      >
        <div style={{ flex: 1 }} />
        <button className="btn btn-primary btn-action btn-lg" onClick={onClose} type="button">
          <i className="icon icon-cross" />
        </button>
      </div>
    );
  }
}
CloseBar.defaultProps = {
  onClose: undefined,
};
CloseBar.propTypes = {
  onClose: PropTypes.func,
};
