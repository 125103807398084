/* eslint-disable no-alert */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import firebase from 'firebase/app';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { Component, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import Label from '../components/Label';
import Modal from '../components/Modal';
import Stats from '../components/Stats';
import Styles from '../components/Styles';
import Tabs from '../components/Tabs';
import Template from '../components/Template';
import database from '../core/database';
import withFirebase from '../hoc/withFirebase';
import { useLanguage } from '../translations/translations.hooks';
import { defaultUserLabel, formatDateAndTimeToday, formatTime } from '../utils';
import { formatQuestions, prepareTopicList } from '../utils/formatQuestions';
import { questionsArrayPropTypes, sessionPropTypes } from '../utils/propTypes';
import Tag from './ClientSession/components/Tag';
import VoteCounter from './ClientSession/components/VoteCounter';
import { ActionButton } from '../components/ActionButton';
import { ToggleButton } from '../components/ToggleButton';
import './ModeratorSession.scss';

const Icon = ({ name }) => <i className={`icon icon-${name}`} />;
Icon.propTypes = { name: PropTypes.string.isRequired };

function filterQuestions(questions, type) {
  if (!questions || questions.length === 0) return questions;
  switch (type) {
    case 'current':
      return questions.filter((q) => !q.state || q.state === 'idle' || q.state === 'visible');
    case 'replied':
      return questions.filter((q) => !!q.response);
    case 'popular':
      return questions.filter((q) => q.voteCount);
    default:
      return questions.filter(({ state }) => state === type);
  }
}

const AnswerButtons = ({ t, id, response, showModal, onDeleteAnswer }) => {
  if (!response) {
    return <Button icon="comment" label={t('btn.reply')} onClick={() => showModal('')} />;
  }
  return (
    <>
      <Button
        icon="pencil-alt"
        label={t('btn.update-answer')}
        onClick={() => showModal(response)}
      />
      <Button icon="trash" label={t('btn.delete-answer')} onClick={() => onDeleteAnswer(id)} />
    </>
  );
};

class QuestionCard extends Component {
  state = {
    isOpen: false,
    response: '',
  };

  handleReply = () => {
    this.setState({ isOpen: true });
  };

  renderCardFooter = () => {
    const {
      id,
      hasModeration,
      state,
      pushToSpeaker,
      pushToSlide,
      onDelete,
      onDeleteAnswer,
      onUpdate,
      t,
      response,
    } = this.props;

    const isPublished = state === 'visible';
    return (
      <>
        {!!response && (
          <div className="card-footer">
            <AnswerButtons
              id={id}
              response={response}
              showModal={(res) => this.setState({ isOpen: true, response: res })}
              onDeleteAnswer={onDeleteAnswer}
              t={t}
            />
          </div>
        )}
        <div className="card-footer">
          <div className="left">
            {hasModeration && (
              <ToggleButton
                checked={isPublished}
                icon="eye"
                label={t('app')}
                onClick={() => onUpdate(id, { state: isPublished ? 'idle' : 'visible' })}
              />
            )}
            <ToggleButton
              checked={pushToSlide}
              icon="users"
              label={t('slide')}
              onClick={() => onUpdate(id, { pushToSlide: !pushToSlide })}
            />
            <ToggleButton
              checked={pushToSpeaker}
              icon="microphone"
              label={t('speaker')}
              onClick={() => onUpdate(id, { pushToSpeaker: !pushToSpeaker })}
            />
          </div>
          <div className="right btn-group">
            {!response && (
              <AnswerButtons
                id={id}
                response={response}
                showModal={(res) => this.setState({ isOpen: true, response: res })}
                onDeleteAnswer={onDeleteAnswer}
                t={t}
              />
            )}
            <ActionButton
              id={id}
              action="archived"
              labels={{ archived: 'btn.archive', 'no-archived': 'btn.restore' }}
              icons={{ archived: 'archive', 'no-archived': 'recycle' }}
              state={state}
              enable={state === 'archived'}
              t={t}
              /* eslint-disable-next-line @typescript-eslint/no-shadow */
              onClick={() => {
                onUpdate(id, {
                  pushToSlide: false,
                  pushToSpeaker: false,
                  state: state === 'archived' ? 'idle' : 'archived',
                });
              }}
            />

            <button className="btn btn-action btn-error" onClick={() => onDelete(id)} type="button">
              <Icon name="delete" />
            </button>
          </div>
        </div>
      </>
    );
  };

  renderShowTime() {
    const { showTime, t } = this.props;
    if (!showTime) return null;
    return ` - ${t('showedAt')} ${formatTime(showTime)}`;
  }

  renderVote() {
    const { voteCount } = this.props;
    return <VoteCounter count={voteCount} />;
  }

  renderAnswerModal = () => {
    const { onReply, id, question, t } = this.props;
    const { response } = this.state;
    return (
      <Modal
        title={t('modal-answer-title')}
        onClose={() => this.setState({ isOpen: false, response: '' })}
        onConfirm={() => {
          onReply(id, response);
          this.setState({ response: '', isOpen: false });
        }}
        t={t}
      >
        <div className="form-group">
          <div>
            <label className="form-label modal-label" htmlFor="response">
              {t('question-label')}
            </label>
            <p>{question}</p>
          </div>
          <label className="form-label modal-label" htmlFor="response">
            {t('answer')}
          </label>
          <textarea
            className="form-input"
            placeholder="Textarea"
            rows="5"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            value={response}
            onChange={(e) =>
              this.setState({
                response: e.target.value,
              })
            }
          />
        </div>
      </Modal>
    );
  };

  render() {
    const { createdAt, question, response, state, theme, t, template, user, voteCount } =
      this.props;
    const { isOpen } = this.state;

    return (
      <div className="card" style={{ marginBottom: 8 }}>
        <div className="card-header">
          <div className="card-subtitle text-gray">
            {formatDateAndTimeToday(createdAt)}
            {this.renderShowTime()}
            {theme && (
              <>
                &nbsp;&nbsp;
                <Label color="primary" rounded>
                  {theme}
                </Label>
              </>
            )}
            {voteCount !== 0 && <>&nbsp;&nbsp;{this.renderVote()}</>}
            {user && (user.firstName || user.lastName) && (
              <>
                <br />
                {t('questionAskedBy')}{' '}
                <Template
                  className="card-body--user-info"
                  template={template?.userLabel || defaultUserLabel}
                  data={user}
                />
              </>
            )}
            <br />
          </div>
        </div>
        <div className="card-body">
          {question}
          {response && (
            <div className="response" style={{ marginTop: 6 }}>
              <span className="label label-secondary">{t('answer')}:</span>
              <p style={{ margin: 0, fontWeight: 'bold', color: '#555' }}> {response}</p>
            </div>
          )}
        </div>
        {isOpen && this.renderAnswerModal()}
        {state !== 'blacklisted' && this.renderCardFooter()}
      </div>
    );
  }
}
QuestionCard.defaultProps = {
  response: undefined,
  showTime: undefined,
  state: 'current',
  template: undefined,
  user: {},
  voteCount: undefined,
};
QuestionCard.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  onDeleteAnswer: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReply: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  response: PropTypes.string,
  showTime: PropTypes.object,
  state: PropTypes.string,
  t: PropTypes.func.isRequired,
  template: PropTypes.object,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  voteCount: PropTypes.number,
};

const ModeratorSession = (props) => {
  const { match, session, questions, stats } = props;
  const { moderatorConfig = {} } = session;
  const { order = 'asc' } = moderatorConfig;
  const { appId, sessionId } = match.params;
  const [tab, setTab] = useState('current');
  const { enableVote, lang = 'fr', template, showQuestions } = session;
  useLanguage(lang);
  const { t } = useTranslation();
  const hasModeration = showQuestions === 'validated';

  const { blacklist } = session;
  const formatedQuestions = formatQuestions(
    questions.map((q) => ({ ...q, state: q.state || 'idle' })),
    blacklist,
  );
  const visibleQuestions = filterQuestions(formatedQuestions, 'visible');

  const [selectedTopic, setSelectedTopic] = useState('');
  const topics = useMemo(() => prepareTopicList(session.topicList), [session.topicList]);

  const questionRef = (questionId) => {
    return database
      .doc(appId)
      .collection('sessions')
      .doc(sessionId)
      .collection('questions')
      .doc(questionId);
  };

  const handleReply = (id, response) => {
    questionRef(id).update({ response, repliedAt: new Date() });
  };

  const handleUpdate = (id, data) => {
    if (data?.state === 'visible') {
      // eslint-disable-next-line no-param-reassign
      data.showTime = new Date();
    }

    questionRef(id).update(data);
  };

  const handleDeleteAnswer = (id) => {
    if (window.confirm(t('confirmDeleteAnswer'))) {
      questionRef(id).update({
        response: firebase.firestore.FieldValue.delete(),
        repliedAt: firebase.firestore.FieldValue.delete(),
        state: 'idle',
      });
    }
  };

  const handleTabChange = (currentTab) => setTab(currentTab);

  const handleDelete = (id) => {
    const question = questions.find((q) => q.id === id);
    if (window.confirm(`${t('confirmDeleteQuestion')} "${question.question}"?`))
      questionRef(id).delete();
  };

  const questionsToShow = useMemo(() => {
    let currentQuestions = filterQuestions(formatedQuestions, tab);
    if (selectedTopic) {
      currentQuestions = currentQuestions.filter((q) => q.theme === selectedTopic);
    }
    if (tab === 'popular') {
      return orderBy(currentQuestions, ['voteCount'], ['desc']);
    }
    return currentQuestions;
  }, [tab, formatedQuestions, selectedTopic]);

  return (
    <div className="container container--moderator" style={{ maxWidth: 800, margin: '0 auto' }}>
      <Styles
        primaryColor={session.primaryColor}
        customCSS={session.customCSS}
        {...session.design}
      />
      <div className="hero" style={{ paddingTop: 16, paddingBottom: 16 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="hero-body" style={{ textAlign: 'center', flex: 1 }}>
            <h1>{session.title}</h1>
            <p>
              {t('questionCount', { count: questions.length })}
              {visibleQuestions.length > 0 && (
                <> - {t('visibleCount', { count: visibleQuestions.length })}</>
              )}
            </p>
          </div>
          {stats && stats.length >= 1 && (
            <div style={{ flex: 1 }}>
              <Stats stats={stats} primaryColor={session.primaryColor} />
            </div>
          )}
        </div>
      </div>
      <div className="content">
        {questions.length === 0 ? (
          <div className="empty">
            <div className="empty-icon">
              <i className="icon icon-3x icon-edit" />
            </div>
            <p className="empty-title h5">{t('moderatorView')}</p>
            <p className="empty-subtitle">{t('noQuestionYet')}</p>
          </div>
        ) : (
          <div>
            <Tabs
              questions={formatedQuestions}
              votes={enableVote}
              onSelect={handleTabChange}
              t={t}
              tab={tab}
            />
            {!!topics?.length && (
              <div className="form-group">
                <label>{t('filter-topic')}</label>
                <select
                  className="form-select"
                  value={selectedTopic}
                  onChange={(e) => setSelectedTopic(e.target.value)}
                >
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <option />
                  {topics.map((topic) => (
                    <option key={topic}>{topic}</option>
                  ))}
                </select>
              </div>
            )}
            {orderBy(questionsToShow, 'createdAt', order).map((question) => {
              return (
                <QuestionCard
                  key={question.id}
                  {...question}
                  onDelete={handleDelete}
                  onDeleteAnswer={handleDeleteAnswer}
                  onReply={handleReply}
                  onUpdate={handleUpdate}
                  t={t}
                  template={template}
                  hasModeration={hasModeration}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

ModeratorSession.defaultProps = {
  questions: [],
  stats: [],
};
ModeratorSession.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appId: PropTypes.string.isRequired,
      sessionId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  questions: questionsArrayPropTypes,
  session: sessionPropTypes.isRequired,
  stats: PropTypes.array,
};

export default withFirebase(ModeratorSession, ({ match }) => {
  const session = database
    .doc(match.params.appId)
    .collection('sessions')
    .doc(match.params.sessionId);
  return {
    session,
    questions: session.collection('questions').orderBy('createdAt', 'asc'),
    stats: session.collection('stats').orderBy('date', 'desc').limit(12),
  };
});
