import React from 'react';

/**
 *
 * @param {*} Component
 * @param {*} firebasePathProvider as (props) => {key: {path, isCollection}}
 */
export default function withFirebase(Component, firebasePathProvider) {
  return class FirebaseComponent extends React.Component {
    state = {};

    constructor(props) {
      super(props);

      this.firebasePaths = firebasePathProvider(props);
    }

    componentDidMount() {
      this._isMounted = true;

      this.firebaseListeners = {};

      Object.entries(this.firebasePaths).forEach(([key, path]) => {
        const isCollection = path._delegate.type !== 'document';

        const firebaseInfo = {
          key,
          path,
        };
        this.firebaseListeners[key] = firebaseInfo;
        if (isCollection) {
          firebaseInfo.data = [];
          firebaseInfo.unsubscribe = path.onSnapshot(
            (querySnapshot) => {
              if (!this._isMounted) return;
              const data = querySnapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
              firebaseInfo.data = data;
              this.setState({ [key]: data });
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            },
          );
        } else {
          firebaseInfo.unsubscribe = path.onSnapshot(
            (querySnapshot) => {
              if (!this._isMounted) return;
              this.setState({ [key]: querySnapshot.data() || {} });
            },
            (err) => {
              console.log('Encountered error', err);
            },
          );
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      Object.keys(this.firebaseListeners).forEach((key) => {
        if (this.firebaseListeners[key].unsubscribe) {
          this.firebaseListeners[key].unsubscribe();
        }
      });
    }

    render() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.firebasePaths) {
        // Some data is still missing...
        // eslint-disable-next-line react/destructuring-assignment
        if (!this.state[key]) {
          return (
            <div className="dashboard-details-screen">
              {/* <Dimmer active>
                <Loader indeterminate size="large">
                  Chargement
                </Loader>
              </Dimmer> */}
            </div>
          );
        }
      }
      return <Component {...this.props} {...this.state} />;
    }
  };
}
