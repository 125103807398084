import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import omit from 'lodash/omit';
import Images from '../utils/Images';
import { UserFlag } from './UserFlag';

function computeInitials(firstName = '', lastName = '') {
  return [(firstName || '')[0], (lastName || '')[0]].filter((v) => v).join('');
}

const spectreImageSizes = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
};

const Avatar = ({ className, user, size, strings, ...props }) => {
  if (!user) return null; // TODO: default avatar ,

  const { firstName, lastName, thumbnail } = user;

  const avatarClassName = cx(`avatar avatar-${size}`, className);

  if (!thumbnail) {
    const initials = computeInitials(firstName, lastName);
    if (!initials) return null;

    return (
      <figure className={avatarClassName} data-initial={initials} {...props}>
        <UserFlag user={{ ...omit(user, 'extra'), ...user.extra }} strings={strings} />
      </figure>
    );
  }
  // TODO: use CDN for images
  return (
    <figure className={avatarClassName} {...props}>
      <img
        {...Images.srcSet.square(thumbnail, spectreImageSizes[size] || 32)}
        alt="avatar"
        className="avatar"
        style={{ position: 'relative' }}
      />
      <UserFlag user={{ ...omit(user, 'extra'), ...user.extra }} strings={strings} />
    </figure>
  );
};

Avatar.defaultProps = {
  className: undefined,
  size: 'md',
  user: undefined,
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ uri: PropTypes.string })]),
    extra: PropTypes.object,
  }),
};
export default Avatar;
