/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../components/Icon';
import Label from '../../../components/Label';

const VoteCounter = ({ count, icon, hasVoted, onClick }) => (
  <Label color={hasVoted ? 'primary' : 'bordered'} className="px-2 py-1" rounded onClick={onClick}>
    <Icon name={icon || 'thumbs-up'} />
    &nbsp;
    {count || 0}
  </Label>
);

VoteCounter.defaultProps = {
  count: 0,
  hasVoted: false,
  icon: 'thumbs-up',
  onClick: undefined,
};

VoteCounter.propTypes = {
  count: PropTypes.number,
  hasVoted: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default VoteCounter;
