import cx from 'classnames';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Div from '../components/EmotionDiv';

import Styles from '../components/Styles';
import Template from '../components/Template';
import database from '../core/database';
import { getTheme } from '../core/Store';
import withFirebase from '../hoc/withFirebase';
import { useLanguage } from '../translations/translations.hooks';
import { questionsArrayPropTypes, sessionPropTypes } from '../utils/propTypes';
import './SlideSession.scss';

const QuestionCard = ({
  question,
  theme,
  user,
  slideExtraLabel,
  displayUserInfo,
  shareUserInfo,
}) => {
  const { extra = {} } = user;
  return (
    <div className="card slide">
      {displayUserInfo && shareUserInfo ? (
        <div className="card-header">
          <div className="question__username">
            {user?.firstName} {user?.lastName}
          </div>
          {slideExtraLabel && (
            <div className="question__user-extraFields">
              <Template template={slideExtraLabel} data={extra} />
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      <div className="card-body">
        {!!theme && <div className="question__theme">{theme}</div>}
        <div className="question__text">{question}</div>
      </div>
    </div>
  );
};

QuestionCard.defaultProps = {
  slideExtraLabel: undefined,
  shareUserInfo: true,
  theme: undefined,
};

QuestionCard.propTypes = {
  question: PropTypes.string.isRequired,
  theme: PropTypes.string,
  user: PropTypes.object.isRequired,
  slideExtraLabel: PropTypes.string,
  displayUserInfo: PropTypes.bool.isRequired,
  shareUserInfo: PropTypes.bool,
};

const SlideSession = ({ session, theme, questions = [] }) => {
  const { t } = useTranslation();
  const slideExtraLabel = get(session, ['template', 'slideExtraLabel']);
  useLanguage(session.lang);
  const extraDesign = theme?.slide?.design || {};
  const { mode = 'normal', cssStyle = {} } = extraDesign;

  return (
    <Div className={cx('container container--slide', `container--mode-${mode}`)} css={cssStyle}>
      <Styles
        fonts={theme?.fonts}
        primaryColor={session.primaryColor}
        {...theme?.slide?.design}
        {...session.design}
        customCSS={` ${session.slide?.design?.customCSS || ''}\n\n ${
          session.design?.customCSS || ''
        }\n\n${extraDesign?.customCSS || ''}`}
      />
      <div className="hero" style={{ paddingTop: 16, paddingBottom: 16 }}>
        <div className="hero-body" style={{ textAlign: 'center' }}>
          <h1>{session.title}</h1>
        </div>
      </div>

      <div className={cx('content', { 'content-grid--2': questions.length > 3 })}>
        {questions.length === 0 && (
          <div className="empty">
            <div className="empty-icon">
              <i className="icon icon-3x icon-time" />
            </div>
            <p className="empty-title h5">{t('waitingQuestion')}</p>
          </div>
        )}
        {orderBy(questions, 'showTime', 'asc').map((q) => (
          <QuestionCard
            key={q.id}
            {...q}
            slideExtraLabel={slideExtraLabel}
            displayUserInfo={session?.showUserName}
          />
        ))}
      </div>
    </Div>
  );
};

SlideSession.defaultProps = {
  questions: [],
};
SlideSession.propTypes = {
  questions: questionsArrayPropTypes,
  session: sessionPropTypes.isRequired,
};

export default withFirebase(SlideSession, ({ match }) => ({
  session: database.doc(match.params.appId).collection('sessions').doc(match.params.sessionId),
  theme: database.doc(match.params.appId).collection('themes').doc(getTheme()),
  questions: database
    .doc(match.params.appId)
    .collection('sessions')
    .doc(match.params.sessionId)
    .collection('questions')
    .where('pushToSlide', '==', true),
}));
