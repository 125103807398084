import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import maxBy from 'lodash/maxBy';
import times from 'lodash/times';
import { PureComponent } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

class Stats extends PureComponent {
  componentDidMount() {
    this.refreshInterval = setInterval(() => this.forceUpdate(), 20 * 1000);
  }

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { stats, primaryColor = '#8884d8' } = this.props;
    const minutes5 = 5 * 60 * 1000;
    const now5 = Math.floor(Date.now() / minutes5) * minutes5;
    const prev5 = now5 - minutes5;

    const statsByDate = keyBy(stats, 'date');
    const currentCount = (maxBy([statsByDate[now5], statsByDate[prev5]], 'count') || {}).count;

    const graphStats = times(12, (n) => {
      const slot = 11 - n;
      const date = now5 - minutes5 * slot;
      return {
        name: `-${slot * 5}m`,
        date,
        count: get(statsByDate[date], 'count', 0),
      };
    });
    return (
      <div>
        <div>Connectés (5 dernières minutes): {currentCount || 0}</div>
        <div>
          <BarChart
            width={300}
            height={120}
            data={graphStats}
            margin={{
              top: 15,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={{ fontSize: 11 }} />
            <YAxis tick={{ fontSize: 11 }} />
            <Tooltip />
            <Bar dataKey="count" fill={primaryColor} />
          </BarChart>
        </div>
      </div>
    );
  }
}

export default Stats;
