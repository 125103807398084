const cdn = {
  'https://api.appcraft.events/platform/v1/S1NAJytbinCJtL/web/en/':
    'https://pes2021-cn.events.3ds.com/',
  'https://api.appcraft.events/platform/v1/g2VHw3KHsyjmY1/web/en/':
    'https://cn.hr.events.lvmh.com/hrsummit/',
  'https://api.appcraft.events/platform/v1/gWbneuSF7xw7GE/web/en/':
    'https://valueup2024-cn.events.3ds.com/',
};

class QAService {
  // eslint-disable-next-line class-methods-use-this
  api(appId, sessionId) {
    // TODO: use custom domain ?
    // const apiHost = `http://localhost:3345/v1/${appId}/web/en/`;
    const apiHost = `https://api.appcraft.events/platform/v1/${appId}/web/en/`;
    // Hack around invalid urls
    const host = (cdn[apiHost] || apiHost).replace('cn.cn', 'cn');
    return `${host}api/qa/sessions/${sessionId}`;
  }

  async fetchJson(appId, sessionId, path = '') {
    const res = await fetch(this.api(appId, sessionId) + path);
    return res.json();
  }

  async sendJson(appId, sessionId, path = '', { method, body }) {
    const res = await fetch(this.api(appId, sessionId) + path, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    });
    return res.json();
  }

  async fetchSession(appId, sessionId) {
    return this.fetchJson(appId, sessionId);
  }

  async fetchQuestions(appId, sessionId, { userId }) {
    return this.fetchJson(appId, sessionId, `/questions?userId=${userId}`);
  }

  async fetchSelfVotes(appId, sessionId, { userId }) {
    return this.fetchJson(appId, sessionId, `/votes?userId=${userId}`);
  }

  async addVote(appId, sessionId, questionId, vote) {
    return this.sendJson(appId, sessionId, `/questions/${questionId}/votes/${vote.userId}`, {
      method: 'POST',
      body: vote,
    });
  }

  async deleteVote(appId, sessionId, questionId, { userId }) {
    return this.sendJson(appId, sessionId, `/questions/${questionId}/votes/${userId}`, {
      method: 'DELETE',
    });
  }

  async postQuestion(appId, sessionId, question) {
    return this.sendJson(appId, sessionId, '/questions', {
      method: 'POST',
      body: question,
    });
  }
}

export default new QAService();
