import React from 'react';
import PropTypes from 'prop-types';

function Modal(props) {
  const { title, children, onClose, onConfirm, t } = props;
  return (
    <div className="modal active" id="modal-id">
      <div className="modal-container">
        <div className="modal-header">
          <button
            className="btn btn-clear float-right"
            type="button"
            aria-label="Close"
            onClick={onClose}
          />
          <div className="modal-title h5">{title}</div>
        </div>
        <div className="modal-body" style={{ paddingTop: 0 }}>
          <div className="content">{children}</div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" type="button" onClick={onConfirm}>
            {t('btn.confirm')}
          </button>
          <button
            className="btn float-right"
            type="button"
            aria-label="Close"
            onClick={onClose}
            style={{ marginLeft: 4 }}
          >
            {t('btn.close')}
          </button>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  title: '',
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default Modal;
