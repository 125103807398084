import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import QuestionList from '../../components/QuestionList';
import Styles from '../../components/Styles';
import TabFilter from '../../components/TabFilter';
import database from '../../core/database';
import { getUserInfo } from '../../core/Store';
import withFirebase from '../../hoc/withFirebase';
import { useLanguage } from '../../translations/translations.hooks';
import { prepareTopicList, sanitizeQuestions } from '../../utils/formatQuestions';
import {
  questionsArrayPropTypes,
  sessionPropTypes,
  votesArrayPropTypes,
} from '../../utils/propTypes';
import '../client.scss';
import './ClientSession.scss';
import CloseBar from './components/CloseBar';
import Pin from './components/Pin';
import QuestionBlock from './components/QuestionBlock';

class RawModeratedClientSession extends Component {
  state = {
    loading: false,
    message: null,
    pin: '',
    selectedTab: 'popularity',
  };

  // componentDidMount() {
  //   this.ping();
  //   this.trackInterval = setInterval(this.ping, 5 * 60 * 1000);
  // }

  // componentWillUnmount() {
  //   if (this.trackInterval) clearInterval(this.trackInterval);
  // }

  // ping = async () => {
  //   const { appId, location, sessionId } = this.props;
  //   const { userId } = getUserInfo(location);

  //   await fetch(
  //     // eslint-disable-next-line max-len
  //     `https://europe-west2-appcraft-events-eu.cloudfunctions.net/api/${appId}/live-questions/${sessionId}/ping?userId=${userId}`,
  //     {
  //       mode: 'no-cors', // 'cors' by default
  //     },
  //   );
  // };

  handleClearMessage = () => this.setState({ message: null });

  handleSend = async (values, t) => {
    this.setState({ loading: true });
    const { appId, location, sessionId, session } = this.props;

    const { question, theme } = values;
    const {
      userId: id = null,
      firstName = null,
      lastName = null,
      email = null,
      thumbnail = null,
    } = getUserInfo(location);

    await database.doc(appId).collection('sessions').doc(sessionId).collection('questions').add({
      createdAt: new Date(),
      question,
      theme,
      voteCount: 0,
      user: { id, firstName, lastName, email, thumbnail },
    });

    const timeout = setInterval(() => {
      this.setState({ message: '' });
      clearInterval(timeout);
    }, 3000);

    this.setState({
      loading: false,
      message: session.messageSent || t('messageSent'),
    });
  };

  handlePinChange = (pin) => this.setState({ pin: (pin || '').trim() });

  toggleVote = async (questionId) => {
    const { appId, location, sessionId, votes } = this.props;
    const { userId } = getUserInfo(location);

    const votesById = groupBy(votes, 'id');
    const voted = !!votesById[questionId];

    const db = database.doc(appId).collection('sessions').doc(sessionId);

    if (!voted) {
      // add vote doc
      await db
        .collection('users')
        .doc(userId)
        .collection('votes')
        .doc(questionId)
        .set({ userId, questionId, createdAt: new Date() });
    } else {
      // delete vote doc
      await db.collection('users').doc(userId).collection('votes').doc(questionId).delete();
    }
  };

  toggleOrder = (value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { onClose, questions, session, votes, t } = this.props;
    const { loading, message, pin, selectedTab } = this.state;
    const {
      blacklist,
      design = {},
      enableOrderBy = false,
      enableVote = false,
      icon,
      topicList,
      showDate = false,
      showQuestions = false,
      showUserName = false,
    } = session;

    const { header, footer } = design;
    const topicArray = prepareTopicList(topicList);
    const hasTopic = topicArray.length > 0;

    const visibleQuestions = questions.filter((q) => q.state === 'visible');
    const sanitizedQuestions = sanitizeQuestions(visibleQuestions, blacklist, {
      enableOrderBy,
      orderType: selectedTab,
    });

    if (session.pin && session.pin !== pin) {
      return (
        <div className="container" style={{ maxWidth: 800, margin: '0 auto' }}>
          <CloseBar onClose={onClose} />
          <Pin session={session} onChange={this.handlePinChange} />
        </div>
      );
    }

    return (
      <div className="container container--client" style={{ maxWidth: 800, margin: '0 auto' }}>
        <Styles
          primaryColor={session.primaryColor}
          customCSS={session.customCSS}
          {...session.design}
        />
        <CloseBar onClose={onClose} />
        {header && header.uri && (
          <div className="image image--header" style={header.wrapperStyle}>
            <img alt="header" src={header.uri} style={header.imageStyle} />
          </div>
        )}

        {enableOrderBy && (
          <TabFilter
            activeTab={selectedTab}
            onChange={this.toggleOrder}
            t={t}
            labelKey="order-by"
            tabs={['popularity', 'date']}
          />
        )}
        <div className="tab-content">
          {/* {showQuestions && ( */}
          <QuestionList
            className={cx({ 'theme-active': hasTopic, 'order-by-active': enableOrderBy })}
            icon={icon}
            enableVote={enableVote}
            showDate={showDate}
            showUserName={showUserName}
            questions={sanitizedQuestions}
            onVote={this.toggleVote}
            votes={votes}
          />
          {/* )} */}
          <QuestionBlock
            loading={loading}
            onSubmit={(values) => this.handleSend(values, t)}
            session={{ ...session, showQuestions: true }}
            topicList={topicArray}
          />
        </div>
        {!!message && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            className="toast toast-success"
            onClick={this.handleClearMessage}
            style={{ marginTop: 8 }}
          >
            {message}
          </div>
        )}

        {footer && footer.uri && (
          <div className="image image--footer" style={footer.wrapperStyle}>
            <img alt="footer" src={footer.uri} style={footer.imageStyle} />
          </div>
        )}
      </div>
    );
  }
}

RawModeratedClientSession.defaultProps = {
  location: undefined,
  onClose: undefined,
  questions: [],
  votes: [],
};
RawModeratedClientSession.propTypes = {
  appId: PropTypes.string.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }),
  onClose: PropTypes.func,
  questions: questionsArrayPropTypes,
  session: sessionPropTypes.isRequired,
  sessionId: PropTypes.string.isRequired,
  votes: votesArrayPropTypes,
};

const ModeratedClientSession = withTranslation()(RawModeratedClientSession);

const ModeratedClientSessionWithQuestions = withFirebase(
  ModeratedClientSession,
  ({ appId, sessionId, location, session }) => {
    const { userId } = getUserInfo(location);
    const sessionRef = database.doc(appId).collection('sessions').doc(sessionId);
    const { /*showQuestions = false, */ enableVote = false } = session;
    const questionsRef = sessionRef.collection('questions').orderBy('createdAt', 'asc');
    const dataToFetch = { questions: questionsRef };
    // if (showQuestions === 'onlyOwnQuestions') {
    //   dataToFetch.questions = questionsRef.where('user.id', '==', userId);
    // }
    if (enableVote) {
      dataToFetch.votes = sessionRef.collection('users').doc(userId).collection('votes');
    }
    return dataToFetch;
  },
);

const ModeratedClientSessionReboot = (props) => {
  const { session } = props;

  useLanguage(session?.lang);

  if (!session) return null; // Not yet ready

  const { showQuestions, enableVote } = session;

  // if (!showQuestions) {
  //   // No need to fetch questions/votes
  //   return <ModeratedClientSession {...props} />;
  // }

  // Reboot component on showQuestions/enableVote change
  return <ModeratedClientSessionWithQuestions key={`${showQuestions}-${enableVote}`} {...props} />;
};

export default withFirebase(ModeratedClientSessionReboot, ({ appId, sessionId }) => ({
  session: database.doc(appId).collection('sessions').doc(sessionId),
}));
