import queryString from 'query-string';
import { uuid } from '../utils';

export function getUserInfo(location) {
  if (location) {
    const { userId, firstName, lastName, email, thumbnail, theme, ...rest } = queryString.parse(
      location.search,
    );
    if (userId) {
      return { userId, firstName, lastName, email, thumbnail, ...rest };
    }
  }

  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = uuid();
    localStorage.setItem('userId', userId);
  }
  return { userId };
}

export function getTheme() {
  return queryString.parse(window.location.search)?.theme || 'default';
}
