import React from 'react';
import PropTypes from 'prop-types';
import { cssForFont } from '../utils/fontUtils';

const Font = ({ type, font }) => {
  if (!font) return null;
  const css = cssForFont(type, font);
  if (!css) return null;
  return (
    <style
      key={`font-${type}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: css,
      }}
    />
  );
};

const fontFilePropType = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  originalFilename: PropTypes.string,
});

Font.defaultProps = {
  font: undefined,
};

Font.propTypes = {
  font: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fallbacks: PropTypes.string,
    files: PropTypes.shape({
      normal: fontFilePropType,
      italic: fontFilePropType,
      bold: fontFilePropType,
      boldItalic: fontFilePropType,
    }),
  }),
  type: PropTypes.oneOf(['body']).isRequired,
};

export default Font;
