import i18next from 'i18next';
import get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/ar';
import 'moment/locale/fr';

function updateLocalLanguageTime() {
  moment.locale(i18next?.language);
}

i18next.on('languageChanged', () => updateLocalLanguageTime());

function normalizeDate(date) {
  if (!date) return null;
  if (date._seconds) return date._seconds * 1000;
  if (date.seconds) return date.seconds * 1000;
  return date;
}

export function formatTime(date) {
  if (!date) return null;
  return moment(normalizeDate(date)).format('LTS');
}

export function formatDateAndTime(date) {
  if (!date) return null;
  return moment(normalizeDate(date)).format('L LTS');
}

export function formatDateAndTimeToday(date) {
  if (!date) return null;
  const m = moment(normalizeDate(date));
  if (m.isSame(new Date(), 'day')) {
    return formatTime(date);
  }
  return formatDateAndTime(date);
}

export function getParameterByName(name, defaultUrl) {
  const url = defaultUrl || window.location.href;
  const cleanName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function normalizeName(name) {
  if (!name) return '';
  return name.toLowerCase().trim();
}

const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

function randomCharacter(string) {
  // eslint-disable-next-line no-bitwise
  return string[(Math.random() * string.length) | 0];
}

export function randomId(length) {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += randomCharacter(characters);
  }
  return str;
}

export function uuid() {
  return randomId(10);
}

export function noop(e) {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
}

export function replaceValues(txt, obj) {
  if (!txt) return '';
  return txt.replace(/{(.*?)}/g, (m, key) => get(obj, key) || '');
}

export const defaultUserLabel = '{{firstName}} {{lastName}}';
